import { SliderProduct } from "@/components/SliderProducts";
import { Product } from "@/DTOs/Product.DTO";
import api from "@/utils/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ToysSection = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        //const response = await api.get(
        //   `/collections/267992301641/products.json`
        // );
        const response = await api.get(`/all_products/267991908425`);
        setProducts(response.data.products);
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    };

    fetchCollections();
  }, []); // Dependência correta do ID

  const handleClick = (id: string | undefined) => {
    navigate(`/product/${id}`);
  };

  return (
    <section>
      <SliderProduct
        loading={!products}
        title="Brincos"
        items={products}
        handleClick={handleClick}
      />
    </section>
  );
};
