export const ButtonGoExplore = () => {
  const handleScrollToExplore = () => {
    const exploreSection = document.getElementById("explore");
    if (exploreSection) {
      exploreSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <button
      className="px-6 py-2 bg-primary text-white filter hover:brightness-110 transition duration-300"
      onClick={handleScrollToExplore}
    >
      Explore nossa arte
    </button>
  );
};
