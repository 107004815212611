const Art2Section = () => {
  return (
    <section className="bg-primary flex flex-col sm:flex-row items-center sm:pt-4 pb-24 mt-20 sm:mt-36 md:mt-40 px-6">
      <div className="sm:w-1/2">
        <img
          src="./assets/art-3.jpg"
          alt="image 3"
          className="sm:object-cover max-w-lg -mt-16 sm:-mt-56 md:-mt-36 m w-full h-auto mx-auto"
        />
      </div>
      <div className="sm:w-1/2 bg-primary pt-4 sm:p-8 sm:pl-16">
        <h2 className="text-4xl font-medium text-black mb-4">
          A arte do ouro em peças únicas
        </h2>
        <p className="text-gray mb-6">
          As arte do ouro em peças feitas especialmente para você, com estilo e
          qualidade unica!
        </p>
        <a href={"/products"} className="bg-black text-primary py-2 px-4 mt-4">
          Explore nossa arte
        </a>
      </div>
    </section>
  );
};

export default Art2Section;
