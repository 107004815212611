import { FaHome } from "react-icons/fa";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray/5">
      <h1 className="text-6xl font-bold text-gray mb-4">404</h1>
      <p className="text-xl text-gray/60 mb-8">
        Ops! A página que você procura não existe.
      </p>
      <a
        href="/"
        className=" flex items-center gap-5 px-6 py-3 bg-primary text-white font-semibold shadow-md filter hover:brightness-105 focus:outline-none"
      >
        Voltar ao inicio <FaHome size={20} />
      </a>
    </div>
  );
};

export default NotFoundPage;
