import { ButtonGoExplore } from "@/components/ButtonGoExplore";
import Art2Section from "@/Sections/Art2Section";
import ArtSection from "@/Sections/ArtSection";
import { ExploreSection } from "@/Sections/ExploreSection";
import { NewArtsSection } from "@/Sections/NewArtsSection";
import AnimatedSection from "@/components/AnimatedSection";
import { ToysSection } from "@/Sections/ToysSection";

const Home = () => {
  return (
    <main>
      <div
        style={{
          backgroundImage: `
          linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 50%),
          linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 50%),
          url(./assets/bg-img.jpg)
        `,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="bg-gray flex flex-col w-full min-h-screen text-marfim justify-end items-center"
      >
        <div className="mb-24 text-center mt-auto">
          <h2 className="max-w-96 h-full text-5xl mb-12">
            A arte do ouro em peças únicas
          </h2>
          <ButtonGoExplore />
        </div>
      </div>
      <div id="explore">
        <AnimatedSection>
          <ExploreSection />
        </AnimatedSection>
        <AnimatedSection>
          <NewArtsSection />
        </AnimatedSection>
      </div>
      <AnimatedSection>
        <ArtSection />
      </AnimatedSection>
      <AnimatedSection>
        <ToysSection />
      </AnimatedSection>
      <AnimatedSection>
        <Art2Section />
      </AnimatedSection>
    </main>
  );
};

export default Home;
