import Slider from "react-slick";
import { useCollections } from "@/context/CollectionsContext";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <div
      className="absolute text-primary right-1 -top-12 w-8 h-8 flex justify-center items-center transition duration-300 cursor-pointer"
      onClick={onClick}
    >
      <IoIosArrowForward size={32} />
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <div
      className="absolute text-primary right-20 -top-12 w-8 h-8 flex justify-center items-center transition duration-300 cursor-pointer"
      onClick={onClick}
    >
      <IoIosArrowBack size={32} />
    </div>
  );
}

export const ExploreSection = () => {
  const navigate = useNavigate();
  const { collections, loading, error } = useCollections();

  const handleClick = (product: string, id: number) => {
    navigate(`/collection/${product}/${id}`);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  if (loading) {
    return (
      <section id="explore" className="py-20">
        <div className="container mx-auto px-8">
          <div className="relative mb-6 sm:mb-12">
            <h1 className="text-left text-3xl sm:text-4xl w-3/5 sm:w-1/2 md:w-1/4">
              Explore a nossa arte em ouro
            </h1>
          </div>
          <Slider {...settings}>
            {[1, 2, 3, 4, 5, 6].map((placeholder) => (
              <div key={placeholder} className="p-4">
                <div className="relative h-96 bg-gray/50 animate-pulse"></div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section id="explore" className="py-20">
        <div className="container mx-auto px-8">
          <div className="relative mb-6 sm:mb-12">
            <h1 className="text-left text-3xl sm:text-4xl w-3/5 sm:w-1/2 md:w-1/4">
              Explore a nossa arte em ouro
            </h1>
          </div>
          <div className="text-red-500">{error}</div>
        </div>
      </section>
    );
  }

  // Adicione uma verificação para garantir que 'collections' não seja undefined
  if (!collections) {
    return (
      <section id="explore" className="py-20">
        <div className="container mx-auto px-8">
          <div className="relative mb-6 sm:mb-12">
            <h1 className="text-left text-3xl sm:text-4xl w-3/5 sm:w-1/2 md:w-1/4">
              Explore a nossa arte em ouro
            </h1>
          </div>
          <div className="text-red-500">No collections available</div>
        </div>
      </section>
    );
  }

  // Filtrar apenas as coleções específicas
  const filteredCollections = collections.filter(
    (collection) =>
      collection.handle.includes("rings") ||
      collection.handle.includes("alianca") ||
      collection.handle.includes("colares") ||
      collection.handle.includes("earrings") ||
      collection.handle.includes("correntes") ||
      collection.title.toLowerCase().includes("anéis") ||
      collection.title.toLowerCase().includes("alianças") ||
      collection.title.toLowerCase().includes("colares") ||
      collection.title.toLowerCase().includes("brincos") ||
      collection.title.toLowerCase().includes("correntes")
  );

  return (
    <section id="explore" className="py-20">
      <div className="container mx-auto px-8">
        <div className="relative mb-6 sm:mb-12">
          <h1 className="text-left text-3xl sm:text-4xl w-3/5 sm:w-1/2 md:w-1/4">
            Explore a nossa arte em ouro
          </h1>
        </div>
        <Slider {...settings}>
          {filteredCollections.map((collection) => (
            <div key={collection.id} className="p-4">
              {collection.image && (
                <div
                  className="relative h-96 transition-all duration-300 cursor-pointer hover:shadow-md hover:scale-105"
                  onClick={() => handleClick(collection.title, collection.id)}
                >
                  <img
                    src={`./assets/img-collection/${collection.handle}.jpg`}
                    alt={collection.image.alt}
                    className="w-full h-full object-cover "
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/45 via-transparent to-transparent"></div>
                  <h2 className="absolute bottom-4 left-6 text-marfim text-2xl p-2">
                    {collection.title}
                  </h2>
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
