import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import Accordion from "@/components/Accordion";
import {
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { IoBagOutline } from "react-icons/io5";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useCart } from "@/context/CartContext";
import { SliderProduct } from "@/components/SliderProducts";
import AnimatedSection from "@/components/AnimatedSection";
import { Popup } from "@/components/PopupSucess";
import { PopupError } from "@/components/PopupError";
import api from "@/utils/api";
import { Product } from "@/DTOs/Product.DTO";
import { calculateFloat } from "@/utils/calculateFloat";
import { formatCurrency } from "@/utils/formatCurrency";
import { extractDetails } from "@/utils/extractDetails";

const ProductPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { addItem } = useCart();

  const [product, setProduct] = useState<Product | null>(null);
  const [recommendedProducts, setRecommendedProducts] = useState<Product[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [popupError, setPopupError] = useState(false);
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [description, setDescription] = useState<string>("");
  const [specs, setSpecs] = useState<string[]>([]);
  const [sku, setSku] = useState<string>("");
  const [quantity, setQuantity] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedVariantPrice, setSelectedVariantPrice] = useState<
    number | null
  >(null);

  useEffect(() => {
    const fetchProduct = async () => {
      if (id) {
        try {
          const response = await api.get(`/products/${id}`);
          const productData = response.data.product;
          setProduct(productData);
          const { description, specs, sku } = extractDetails(
            productData.body_html
          );
          setDescription(description);
          setSpecs(specs);
          setSku(sku);

          // Selecionar a primeira variante como padrão
          if (productData.variants && productData.variants.length > 0) {
            setSelectedVariantPrice(parseFloat(productData.variants[0].price));
            setSelectedSize(productData.variants[0].title);
            setSku(productData.variants[0].sku);
          }
        } catch (error) {
          setError("Erro ao buscar o produto");
        }
      }
    };

    fetchProduct();
  }, [id]);

  useEffect(() => {
    const fetchRecommendedProducts = async () => {
      try {
        const response = await api.get("/all_products");
        setRecommendedProducts(response.data.products);
      } catch (error) {
        setError("ops, erro no fetch dos produtos recomendados");
      }
    };

    fetchRecommendedProducts();
  }, []);

  const handleSizeSelection = (size: string) => {
    setSelectedSize(size);
    if (product) {
      const variant = product.variants.find((v) => v.title === size);
      if (variant) {
        setSelectedVariantPrice(parseFloat(variant.price));
        setSku(variant.sku); // Atualizar o SKU da variante selecionada
      }
    }
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCart = () => {
    if (product && selectedSize && selectedVariantPrice !== null) {
      addItem({
        id: product.id.toString(),
        src: product.images[0]?.src || "", // Adiciona verificação para garantir que a imagem existe
        sku: sku || "",
        name: product.title,
        price: selectedVariantPrice, // Adiciona o preço da variante selecionada
        variant: selectedSize,
        quantity: quantity,
        link: `${window.location.origin}/product/${product?.id}`,
      });
      setIsPopupOpen(true);
    }
  };

  const handleBuyNow = () => {
    if (selectedSize) {
      const phoneNumber = process.env.NEXT_PUBLIC_PHONE_NUMBER;
      const messageTemplate = `*Pedido:*\n\nProduto: ${product?.title}\nSKU: ${sku}\nVariante: ${selectedSize}\nQuantidade: ${quantity}\nLink: ${window.location.origin}/product/${product?.id}`;

      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
        messageTemplate
      )}`;
      window.open(whatsappUrl, "_blank");
    } else {
      setError("Você deve selecionar uma variação");
      setPopupError(true);
    }
  };

  const handleRedirectProduct = (id: string | undefined) => {
    navigate(`/product/${id}`);
  };

  if (!product) {
    return <ProductPageSkeleton />;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <main className="container mx-auto px-4 pt-28 pb-8">
      <p className="text-xs mb-4 text-gray font-normal leading-normal tracking-wide">
        <a href="/">inicio</a> /{" "}
        <span className="cursor-pointer">{product.product_type}</span>
      </p>
      <div className="flex flex-col md:flex-row justify-center items-center md:items-start">
        <div className="w-full md:w-3/5 max-w-md p-4">
          {product.images.length === 1 ? (
            <div className="w-full">
              {product.images.length > 0 ? (
                <img
                  src={product.images[0]?.src}
                  alt={product.images[0]?.alt || product.title}
                  className=" inline-block mx-auto h-auto object-cover border-[1px] border-solid border-gray/20"
                />
              ) : (
                <div className=" inline-block mx-auto w-full h-auto object-cover border-[1px] border-solid border-gray/20" />
              )}
            </div>
          ) : (
            <Slider {...settings} className="flex">
              {product.images.map((image) => (
                <div key={image.id} className="w-full">
                  <img
                    src={image.src}
                    alt={image.alt || product.title}
                    className=" inline-block mx-auto h-auto object-cover border-[1px] border-solid border-gray/20"
                  />
                </div>
              ))}
            </Slider>
          )}
        </div>

        <div className="flex flex-col w-full h-full md:w-2/5 max-w-md px-4">
          <p
            className="text-xs mb-4 text-gray uppercase leading-10 tracking-widest"
            style={{ letterSpacing: "10px !important" }}
          >
            {product.product_type}
          </p>
          <h1 className="text-3xl font-medium mb-4">{product.title}</h1>
          {selectedVariantPrice ? (
            <div className="flex gap-4 text-2xl font-bold mb-10">
              <p className="">
                {Boolean(process.env.PRICES) &&
                  formatCurrency(calculateFloat(selectedVariantPrice))}
              </p>
            </div>
          ) : (
            "Selecione um tamanho"
          )}

          <div className="mb-4">
            {product.options[0].values.length > 1 && (
              <>
                <h3 className="text-lg font-medium mb-2">Tamanhos</h3>
                <div className="flex flex-wrap mb-4">
                  {product.options[0].values.map((variant) => (
                    <button
                      key={variant}
                      onClick={() => handleSizeSelection(variant)}
                      className={`border-[1px] border-solid border-gray/20 h-12 min-w-12 flex items-center justify-center hover:bg-black hover:text-marfim ${
                        selectedSize === variant
                          ? "bg-black text-marfim"
                          : "bg-marfim text-black"
                      }`}
                    >
                      {variant}
                    </button>
                  ))}
                </div>
              </>
            )}

            <button
              onClick={handleBuyNow}
              className="w-full flex justify-center items-center bg-primary text-black md:text-sm text-base py-3 px-4 mt-8 filter hover:brightness-110 transition duration-300"
            >
              COMPRAR AGORA
              <IoBagOutline className="ml-4" size={20} />
            </button>

            <div className="flex flex-col md:flex-row gap-4 md:gap-0 items-center mt-3">
              <div className="w-full h-12 md:max-w-28 flex justify-between items-center border border-gray/20 px-2 py-1 md:mr-4">
                <button onClick={handleDecrement} className="text-black">
                  <AiOutlineMinus size={20} />
                </button>
                <span className="px-4">{quantity}</span>
                <button onClick={handleIncrement} className="text-black">
                  <AiOutlinePlus size={20} />
                </button>
              </div>
              <button
                onClick={handleAddToCart}
                className="w-full flex justify-center items-center bg-black text-marfim md:text-sm text-base py-3 px-4 filter hover:brightness-110 transition duration-300"
              >
                ADICIONAR AO CARRINHO
                <AiOutlineShoppingCart className="ml-2" size={20} />
              </button>
            </div>
            <h3 className="font-bold text-md mt-8">SKU: {sku}</h3>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 gap-10">
        <Accordion title="Descrição" content={description} />
        {specs && specs.length > 0 && (
          <Accordion title="Ficha Técnica" content={specs.join("<br>")} />
        )}
      </div>
      <AnimatedSection>
        <SliderProduct
          loading={!recommendedProducts}
          items={recommendedProducts as Product[]}
          title="Produtos que você pode gostar"
          handleClick={handleRedirectProduct}
        />
      </AnimatedSection>
      <Popup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        title="Item Adicionado ao Carrinho!"
      ></Popup>
      <PopupError
        isOpen={popupError}
        onClose={() => setPopupError(false)}
        title={error || ""}
      />
    </main>
  );
};

const ProductPageSkeleton: React.FC = () => {
  return (
    <main className=" relative container mx-auto px-4 pt-28 pb-8 animate-pulse">
      <div className="flex flex-col md:flex-row justify-center items-center md:items-start">
        <div className="w-full md:w-3/5 max-w-md p-4">
          <div className="w-full h-72 bg-gray/50"></div>
        </div>

        <div className="flex flex-col w-full h-full md:w-2/5 max-w-md px-4 pt-4">
          <div className="text-xs mb-4 bg-gray/50 h-3 w-32"></div>
          <div className="text-3xl font-medium mb-16 bg-gray/50 h-6 w-48"></div>
          <div className="mb-4">
            <div className="text-lg font-medium mb-2 bg-gray/50 h-4 w-24"></div>
            <div className="flex flex-wrap mb-4 gap-2">
              <div className="border-[1px] border-solid border-gray/20 bg-gray/50 h-12 w-12"></div>
              <div className="border-[1px] border-solid border-gray/20 bg-gray/50 h-12 w-12"></div>
              <div className="border-[1px] border-solid border-gray/20 bg-gray/50 h-12 w-12"></div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 md:gap-0 items-center mt-8">
              <div className="w-full h-12 md:max-w-28 bg-gray/50"></div>
              <div className="w-full flex justify-center items-center bg-gray/50 h-12"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="bg-gray/50 h-8 w-32 mb-2"></div>
        <div className="bg-gray/50 h-4 w-full"></div>
        <div className="bg-gray/50 h-4 w-full"></div>
      </div>

      <AnimatedSection>
        <SliderProduct
          loading={true}
          items={[]}
          title="Produtos que você pode gostar"
          handleClick={() => {}}
        />
      </AnimatedSection>
    </main>
  );
};

export default ProductPage;
