import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import RouterApp from "./Router.tsx";

import { CollectionsProvider } from "./context/CollectionsContext.tsx";
import { CartProvider } from "./context/CartContext";
import { FilterProvider } from "./context/SearchContext";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <CollectionsProvider>
      <CartProvider>
        <FilterProvider>
          <RouterApp />
        </FilterProvider>
      </CartProvider>
    </CollectionsProvider>
  </React.StrictMode>
);
