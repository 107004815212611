import { IoIosSearch, IoMdClose } from "react-icons/io";
import { ShoppingCart } from "@/components/ShoppingCart";
import { Search } from "@/components/Search";
import { motion, AnimatePresence } from "framer-motion";
import { NavHeaderMobile } from "./mobile-nav";
import { useFilterContext } from "@/context/SearchContext"; // Importa o contexto

export const ClientHeader = () => {
  const { isSearchOpen, setIsSearchOpen } = useFilterContext(); // Usa o contexto para gerenciar o estado

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <div className="flex h-full items-center gap-5">
      {/* <LanguageSelector /> */}
      <AnimatePresence mode="wait">
        {isSearchOpen ? (
          <motion.div
            key="close"
            initial={{ opacity: 0, rotate: -90 }}
            animate={{ opacity: 1, rotate: 0 }}
            exit={{ opacity: 0, rotate: 90 }}
            transition={{ duration: 0.3 }}
            onClick={toggleSearch}
          >
            <IoMdClose className="text-marfim text-2xl cursor-pointer" />
          </motion.div>
        ) : (
          <motion.div
            key="search"
            initial={{ opacity: 0, rotate: 90 }}
            animate={{ opacity: 1, rotate: 0 }}
            exit={{ opacity: 0, rotate: -90 }}
            transition={{ duration: 0.3 }}
            onClick={toggleSearch}
          >
            <IoIosSearch className="text-marfim text-2xl cursor-pointer" />
          </motion.div>
        )}
      </AnimatePresence>
      <ShoppingCart />
      <span className="md:hidden">
        <NavHeaderMobile />
      </span>
      <Search isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
    </div>
  );
};
