import { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoMdClose } from "react-icons/io";

export const Popup = ({
  isOpen,
  onClose,
  title,
}: {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000);

      // Limpa o timeout se o popup fechar antes dos 4 segundos
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed bottom-8 right-8 z-50 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="flex justify-between rounded-lg py-2 px-4 max-w-xs w-full bg-green-600"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
          >
            <h2 className="text-sm text-marfim font-medium">{title}</h2>
            <button
              className="text-marfim text-xl hover:text-red-600 transition-all duration-200"
              onClick={onClose}
            >
              <IoMdClose />
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
