import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface CartItem {
  id: string;
  src: string;
  name: string;
  price: number;
  sku: string;
  variant: string;
  quantity: number;
  link: string;
}

interface CartContextType {
  items: CartItem[];
  addItem: (item: CartItem) => void;
  removeItem: (id: string, variant: string) => void;
  clearCart: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [items, setItems] = useState<CartItem[]>(() => {
    // Carregar itens do localStorage ao montar o componente
    if (typeof window !== "undefined") {
      const storedItems = localStorage.getItem("cartItems");
      return storedItems ? JSON.parse(storedItems) : [];
    }
    return [];
  });

  // Salvar itens no localStorage sempre que a lista de itens mudar
  useEffect(() => {
    if (items.length > 0) {
      localStorage.setItem("cartItems", JSON.stringify(items));
    } else {
      localStorage.removeItem("cartItems");
    }
  }, [items]);

  const addItem = (item: CartItem) => {
    setItems((prevItems) => {
      const existingItem = prevItems.find(
        (prevItem) =>
          prevItem.id === item.id && prevItem.variant === item.variant
      );
      if (existingItem) {
        return prevItems.map((prevItem) =>
          prevItem.id === item.id && prevItem.variant === item.variant
            ? { ...prevItem, quantity: prevItem.quantity + item.quantity }
            : prevItem
        );
      }
      return [...prevItems, item];
    });
  };

  const removeItem = (id: string, variant: string) => {
    setItems((prevItems) =>
      prevItems.filter((item) => !(item.id === id && item.variant === variant))
    );
  };

  const clearCart = () => {
    setItems([]);
  };

  return (
    <CartContext.Provider value={{ items, addItem, removeItem, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
