import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import api from "@/utils/api";
import { Product } from "@/DTOs/Product.DTO";
import { debounce } from "lodash";

interface FilterContextProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  products: Product[] | null;
  filteredProducts: Product[] | null;
  loading: boolean;
  error: string | null;
  isSearchOpen: boolean;
  setIsSearchOpen: (isOpen: boolean) => void;
  loadMoreProducts: () => void;
  hasMoreProducts: boolean;
}

interface FetchParams {
  limit: number;
  since_id?: number;
  searchTerm?: string;
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined);

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterContext must be used within a FilterProvider");
  }
  return context;
};

export const FilterProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [products, setProducts] = useState<Product[] | null>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[] | null>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [currentSinceId, setCurrentSinceId] = useState<number | null>(null);
  const [hasMoreProducts, setHasMoreProducts] = useState<boolean>(true);

  // Função responsável por buscar os produtos
  const fetchProducts = async (since_id?: number, term?: string) => {
    setLoading(true);
    try {
      const params: FetchParams = {
        limit: 10,
        since_id,
      };

      if (term && term.trim() !== "") {
        params.searchTerm = term;
      }

      const response = await api.get("/products_filtered_paginated", {
        params,
      });
      const fetchedProducts = response.data.products;

      if (fetchedProducts.length < 10) {
        setHasMoreProducts(false); // Sem mais produtos para carregar
      }

      if (term) {
        // Se estiver buscando por um termo, atualiza somente os produtos filtrados
        setFilteredProducts((prevProducts) =>
          since_id
            ? [...(prevProducts || []), ...fetchedProducts]
            : fetchedProducts
        );
      } else {
        // Caso contrário, atualiza todos os produtos
        setProducts((prevProducts) =>
          since_id
            ? [...(prevProducts || []), ...fetchedProducts]
            : fetchedProducts
        );
      }

      setCurrentSinceId(response.data.since_id);
    } catch (error) {
      setError("Ops, erro no fetch dos produtos");
      console.error("Fetch products error:", error);
    }
    setLoading(false);
  };

  // Carregar produtos somente na montagem inicial, sem termo de busca
  useEffect(() => {
    if (!searchTerm) {
      fetchProducts();
    }
  }, []); // Somente na montagem inicial

  // Carregar produtos quando searchTerm mudar
  useEffect(() => {
    if (searchTerm) {
      setFilteredProducts([]); // Limpa os produtos filtrados antes da busca
      fetchProducts(undefined, searchTerm.trim());
    }
  }, [searchTerm]);

  const debouncedFetchProducts = useCallback(
    debounce((since_id?: number, term?: string) => {
      fetchProducts(since_id, term);
    }, 500),
    []
  );

  const loadMoreProducts = () => {
    if (currentSinceId !== null && hasMoreProducts) {
      debouncedFetchProducts(currentSinceId, searchTerm);
    }
  };

  return (
    <FilterContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        products,
        filteredProducts,
        loading,
        error,
        isSearchOpen,
        setIsSearchOpen,
        loadMoreProducts,
        hasMoreProducts,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
