import { useCollections } from "@/context";
import { useState, useEffect, useRef } from "react";
import { RiArrowDownDoubleFill, RiArrowUpDoubleFill } from "react-icons/ri";
import { motion } from "framer-motion";
import { excludedIds } from ".";
import { CollectionListing } from "@/context/CollectionsContext";
import { useNavigate } from "react-router-dom";

export const NavHeaderDesktop = () => {
  const navigate = useNavigate();
  const { collections } = useCollections();
  const [allItems, setAllItems] = useState(false);
  // const [showButton, setShowButton] = useState(false);
  const [collectionsFiltered, setCollectionsFiltered] = useState<
    CollectionListing[]
  >([]);
  const listRef = useRef<HTMLUListElement>(null);

  const toggleAllItems = () => {
    setAllItems((prev) => !prev);
  };

  const handleClick = (product: string, id: number) => {
    navigate(`/collection/${product}/${id}`);
  };

  useEffect(() => {
    if (collections) {
      const filteredCollections = collections.filter(
        (collection) => !excludedIds.includes(collection.id)
      );
      setCollectionsFiltered(filteredCollections);
    }
  }, [collections]);

  // ISSO SERVIA PARA VERIFICAR O QUE O BOTÃO VAI SER VISIVEL A PARTIR DA QUANTIDADE DE ITENS
  // useEffect(() => {
  //   const checkOverflow = () => {
  //     if (listRef.current) {
  //       const isOverflowing =
  //         listRef.current.scrollWidth > listRef.current.clientWidth;
  //       setShowButton(isOverflowing || allItems);
  //     }
  //   };

  //   checkOverflow();
  //   window.addEventListener("resize", checkOverflow);

  //   return () => {
  //     window.removeEventListener("resize", checkOverflow);
  //   };
  // }, [allItems]);

  // useEffect(() => {
  //   if (listRef.current) {
  //     const isOverflowing =
  //       listRef.current.scrollWidth > listRef.current.clientWidth;
  //     setShowButton(isOverflowing || allItems);
  //   }
  // }, [collections, allItems]);

  return (
    <div className="hidden md:flex items-start px-2">
      <div className="overflow-hidden w-full max-w-xl flex h-full items-center">
        <motion.ul
          ref={listRef}
          className={`flex ${
            allItems ? "flex-wrap mt-4" : "overflow-hidden"
          } h-full gap-x-10 gap-y-5 justify-start items-center pb-2`}
          style={{ width: allItems ? "auto" : "1000px" }}
          initial={{ height: allItems ? "auto" : 0 }}
          animate={{ height: allItems ? "auto" : 18 }}
          transition={{ duration: 0.5 }}
        >
          {collectionsFiltered.length > 0 &&
            collectionsFiltered.map((collection) => (
              <li
                key={collection.id}
                className="text-sm block text-marfim whitespace-nowrap cursor-pointer"
                onClick={() => handleClick(collection.title, collection.id)}
              >
                {collection.title}
              </li>
            ))}
        </motion.ul>
      </div>
      <button
        onClick={toggleAllItems}
        className="text-marfim text-xl mb-auto ml-4"
      >
        {allItems ? (
          <RiArrowUpDoubleFill className="mt-3" />
        ) : (
          <RiArrowDownDoubleFill />
        )}
      </button>
    </div>
  );
};
