import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { truncate } from "@/utils/truncate";
import { Product } from "@/DTOs/Product.DTO";
import { formatCurrency } from "@/utils/formatCurrency";
import { calculateFloat } from "@/utils/calculateFloat";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <div
      className="absolute text-primary right-1 -top-12 w-8 h-8 flex justify-center items-center transition duration-300 cursor-pointer"
      onClick={onClick}
    >
      <IoIosArrowForward size={32} />
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <div
      className="absolute text-primary right-20 -top-12 w-8 h-8 flex justify-center items-center transition duration-300 cursor-pointer"
      onClick={onClick}
    >
      <IoIosArrowBack size={32} />
    </div>
  );
}

export interface SliderProductProps {
  title: string;
  items: Product[];
  handleClick: (id?: string) => void;
  loading: boolean;
}

export const SliderProduct = ({
  title,
  items,
  handleClick,
  loading,
}: SliderProductProps) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1, // Adicionado para iniciar a partir do segundo produto
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section className="py-16">
      <div className="container mx-auto px-8 ">
        <div className="relative mb-6 sm:mb-12">
          <h1 className="text-left text-3xl sm:text-4xl w-3/5 sm:w-1/2 md:w-1/4">
            {title}
          </h1>
        </div>
        <Slider {...settings} className="pb-6">
          {loading
            ? [1, 2, 3, 4, 5, 6].map((placeholder) => (
                <div key={placeholder} className="p-2 py-6">
                  <div className="w-48 h-48 sm:w-52 sm:h-52 mx-auto bg-gray/50 animate-pulse"></div>
                  <div className="w-48 h-4 sm:w-52 sm:h-52 mx-auto bg-gray/50 animate-pulse mt-2"></div>
                  <div className="w-28 h-4 sm:w-52 sm:h-52 mx-auto bg-gray/50 animate-pulse mt-2"></div>
                </div>
              ))
            : items.map((item) => (
                <div key={item.id} className="p-2 py-6">
                  {item.image && (
                    <div
                      className="w-48 h-48 sm:w-52 sm:h-52 mx-auto transition-all duration-300 cursor-pointer hover:shadow-md hover:scale-105"
                      onClick={() => handleClick(String(item.id))}
                    >
                      <img
                        src={item.image.src}
                        alt={item.image.alt || ""}
                        className="w-full h-full object-cover border-[1px] border-solid border-gray/20 "
                      />
                      <h2 className="text-black text-md font-medium py-2">
                        {truncate(item.title, 62)}
                      </h2>
                      {Boolean(process.env.PRICES) &&
                        item.variants.length > 0 && (
                          <p className="text-lg font-medium">
                            {formatCurrency(
                              calculateFloat(parseFloat(item.variants[0].price))
                            )}
                          </p>
                        )}
                    </div>
                  )}
                </div>
              ))}
        </Slider>
      </div>
    </section>
  );
};
