export const extractDetails = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const descriptionParts = [];
  const specs = [];
  let sku = "";

  const paragraphs = Array.from(doc.querySelectorAll("p"));
  const lists = Array.from(doc.querySelectorAll("ul li"));

  let isTechSheet = false;

  for (const p of paragraphs) {
    let text = p.textContent || "";
    if (text.includes("Ficha Técnica:")) {
      isTechSheet = true;
      continue;
    }
    if (isTechSheet) {
      if (text.trim() === "") break;
      specs.push(text);
    } else {
      if (text.includes("Descricao do Produto")) {
        text = text.replace("Descricao do Produto", "").trim();
      }
      if (text.includes("Product Description")) {
        text = text.replace("Product Description", "\n\nEnglish:\n").trim();
      }
      descriptionParts.push(text);
    }
  }

  for (const li of lists) {
    const text = li.textContent || "";
    specs.push(text.trim());
  }

  const skuElements = doc.querySelectorAll("p, ul li, b, div");

  for (const elem of skuElements) {
    const text = elem.textContent || "";
    const regex = /sku\s*:?\s*(\w+)/i;
    const match = text.match(regex);

    if (match) {
      sku = match[1].trim();
      break;
    }
  }

  return {
    description: descriptionParts
      .join(" ")
      .replace(/\s+/g, " ")
      .replace(/ ([A-Z])/g, "\n$1"),
    specs,
    sku,
  };
};
