import { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { useCollections } from "@/context";
import { motion, AnimatePresence } from "framer-motion";
import { excludedIds } from ".";
import { useNavigate } from "react-router-dom";

export const NavHeaderMobile = () => {
  const navigate = useNavigate();
  const { collections } = useCollections();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (product: string, id: number) => {
    navigate(`/collection/${product}/${id}`);
  };

  const filteredCollections = collections?.filter(
    (collection) => !excludedIds.includes(collection.id)
  );

  return (
    <div className="md:hidden">
      <button onClick={toggleDrawer} className="text-2xl text-marfim">
        <FiMenu />
      </button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 z-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={toggleDrawer}
            >
              <motion.div
                className="fixed top-0 right-0 w-full sm:w-80 h-full bg-marfim shadow-lg p-4"
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ type: "tween" }}
              >
                <button onClick={toggleDrawer} className="text-xl mb-4">
                  <AiOutlineClose />
                </button>
                <ul className="flex flex-col max-h-full overflow-auto no-scrollbar">
                  {filteredCollections.map((collection) => (
                    <li
                      key={collection.id}
                      className="text-lg py-2 sm:py-1 px-1 hover:bg-secondary hover:text-marfim text-gray cursor-pointer"
                      onClick={() =>
                        handleClick(
                          collection.title as string,
                          collection.id as number
                        )
                      }
                    >
                      {collection.title}
                    </li>
                  ))}
                </ul>
              </motion.div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};
