export const calculateFloat = (value: number) => {
  const percentage = process.env.PRICE_VARIATION;

  if (percentage) {
    if (!value) return 0;
    const result = value * parseFloat(percentage);

    return result;
  }

  return value;
};
