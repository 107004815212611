import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SkeletonCard } from "@/components/SkeletonCard";
import { useFilterContext } from "@/context/SearchContext";
import api from "@/utils/api";
import { Product } from "@/DTOs/Product.DTO";
import { formatCurrency } from "@/utils/formatCurrency";
import { calculateFloat } from "@/utils/calculateFloat";
import { truncate } from "@/utils/truncate";

const ProductByCollection = () => {
  const { product, id } = useParams<{ product: string; id: string }>();
  const [items, setItems] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sinceId, setSinceId] = useState<number | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const { searchTerm } = useFilterContext();
  const navigate = useNavigate();

  const fetchProducts = async (
    since_id: number | null = null,
    reset = false
  ) => {
    if (reset) {
      setItems([]);
      setSinceId(null);
      setHasMore(true);
    }
    setLoadingMore(true);
    try {
      const params = since_id ? { since_id, limit: 10 } : { limit: 10 };
      const response = await api.get(`/collections/${id}/products_paginated`, {
        params,
      });
      const fetchedProducts = response.data.products;

      setItems((prevItems) =>
        reset ? fetchedProducts : [...prevItems, ...fetchedProducts]
      );
      setSinceId(
        fetchedProducts.length > 0
          ? fetchedProducts[fetchedProducts.length - 1].id
          : null
      );
      setHasMore(fetchedProducts.length === 10);
    } catch (error) {
      setError("Ops, ocorreu um erro no fetch dos produtos!");
    } finally {
      setLoadingMore(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchProducts(null, true);
    }
  }, [id]);

  const loadMore = async () => {
    if (hasMore && sinceId !== null) {
      await fetchProducts(sinceId);
    }
  };

  const handleClick = (id: number) => {
    navigate(`/product/${id}`);
  };

  if (error) return <div>Error loading item: {error}</div>;

  const filteredItems = items.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <main className="container mx-auto px-4 pt-28 pb-8 min-h-screen">
      <p className="text-xs mb-4 text-gray font-normal leading-normal tracking-wide">
        <a href="/">inicio</a> /{" "}
        <span className="cursor-pointer">
          {decodeURIComponent(product as string)}
        </span>
      </p>
      <h1 className="text-3xl sm:text-4xl font-normal leading-10 mb-8">
        {decodeURIComponent(product as string)}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
        {loading && items.length === 0 ? (
          Array.from({ length: 10 }).map((_, index) => (
            <SkeletonCard key={index} />
          ))
        ) : filteredItems.length > 0 ? (
          filteredItems.map((item) =>
            item.images &&
            Array.isArray(item.images) &&
            item.images.length > 0 ? (
              item.images.map((image, imgIndex) => (
                <div
                  key={`${item.id}-${image.id}-${imgIndex}`}
                  className="hover:scale-105 hover:shadow-lg transition-all duration-300 cursor-pointer mx-auto max-w-64 p-4"
                  onClick={() => handleClick(item.id)}
                >
                  <div className="border-[1px] border-solid border-gray/20">
                    {image.src.length > 0 ? (
                      <>
                        <img
                          src={image.src}
                          alt={image.alt || "Product Image"}
                          width={image.width}
                          height={image.height}
                          className="w-full h-48 object-cover"
                        />{" "}
                      </>
                    ) : (
                      <div className="w-full h-48 bg-gray/50 flex items-center justify-center ">
                        No image
                      </div>
                    )}
                  </div>
                  <p className="text-sm text-gray mt-2">{item.product_type}</p>
                  {item.variants && item.variants.length > 0 && (
                    <p className="text-lg mt-2 font-medium">
                      {formatCurrency(
                        calculateFloat(parseFloat(item.variants[0].price))
                      )}
                    </p>
                  )}
                  <h2 className="mt-2 text-lg font-medium">
                    {truncate(item.title, 75)}
                  </h2>
                </div>
              ))
            ) : (
              <div
                key={item.id}
                className="hover:scale-105 hover:shadow-lg transition-all duration-300 cursor-pointer mx-auto w-64 p-4"
                onClick={() => handleClick(item.id)}
              >
                <div className="border-[1px] border-solid border-gray/20">
                  <p className="text-sm text-gray mt-2">{item.product_type}</p>
                  {item.variants && item.variants.length > 0 && (
                    <p className="text-lg mt-2 font-medium">
                      {formatCurrency(
                        calculateFloat(parseFloat(item.variants[0].price))
                      )}
                    </p>
                  )}
                  <h2 className="mt-2 text-lg font-medium">
                    {truncate(item.title, 75)}
                  </h2>
                </div>
              </div>
            )
          )
        ) : !loading && filteredItems.length === 0 ? (
          <h2 className="text-lg">Ops... Produtos não encontrados!</h2>
        ) : null}
      </div>
      {hasMore && !loadingMore && (
        <div className="flex justify-center">
          <button
            className="flex justify-center items-center bg-primary text-white md:text-sm text-base py-3 px-4 mt-12 filter hover:brightness-110 transition duration-300"
            onClick={loadMore}
          >
            Carregar mais
          </button>
        </div>
      )}
      {loadingMore && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
          {Array.from({ length: 10 }).map((_, index) => (
            <SkeletonCard key={index} />
          ))}
        </div>
      )}
    </main>
  );
};

export default ProductByCollection;
