import React, { ChangeEvent, useState } from "react";
import { useFilterContext } from "@/context/SearchContext";
import { motion, AnimatePresence } from "framer-motion";
import { IoIosSearch } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";

interface SearchProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Search: React.FC<SearchProps> = ({ isOpen }) => {
  const { setSearchTerm, setIsSearchOpen, products } = useFilterContext();
  const [localSearchTerm, setLocalSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    setSearchTerm(localSearchTerm);
    setIsSearchOpen(true);
    if (
      products &&
      products.length > 0 &&
      location.pathname.startsWith("/collection")
    ) {
      return;
    }
    navigate(`/products?search=${localSearchTerm}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed top-24 right-4 md:right-12 w-full"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex justify-end items-center">
            <input
              type="text"
              value={localSearchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              placeholder="Search..."
              className="w-full max-w-56 ml-auto px-2 py-1 border-2 border-black rounded-lg focus:outline-none focus:border-primary"
            />
            <IoIosSearch
              className="text-xl text-gray -ml-7 cursor-pointer"
              onClick={handleSearch}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
