import { NavHeaderDesktop } from "./desktop-nav";
import { ClientHeader } from "./ClientHeader"; // Import the client-side component
import { Logo } from "./logo";

export const excludedIds = [
  271514075209, 271514009673, 276852015177, 266624499785, 278646653001,
];

export const Header = () => {
  const title = process.env.TITLE || "";
  return (
    <header className="fixed z-10 top-0 w-full">
      <div className="h-9 flex bg-primary justify-center items-center">
        <div className="text-black text-xs font-bold font-['Satoshi Variable'] uppercase leading-[14.40px]">
          Frete grátis em compras acima de $200. Parcele em até 12x.
        </div>
      </div>
      <div className="flex bg-black justify-between items-center md:justify-around px-6 py-2 md:px-0">
        <div className="flex">
          <a
            href="/"
            className=" flex gap-2 text-marfim text-2xl uppercase leading-10 tracking-widest"
            style={{ letterSpacing: "10px !important" }}
          >
            <Logo />
            {title}
          </a>
        </div>

        <div className="hidden md:flex">
          <NavHeaderDesktop />
        </div>
        <ClientHeader />
      </div>
    </header>
  );
};
